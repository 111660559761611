(() => {
  const refs = {
    openMenu: document.querySelector("[data-menu-open]"),
    closeMenu: document.querySelector("[data-menu-close]"),
    menu: document.querySelector("[data-menu]"),
    menuItems: document.querySelectorAll(
      "[data-menu] .header-mobile-menu_item"
    ),
  };

  refs.openMenu.addEventListener("click", toggleMenu);
  refs.closeMenu.addEventListener("click", toggleMenu);
  refs.menuItems.forEach((item) => {
    item.addEventListener("click", toggleMenu);
  });

  refs.menu.addEventListener("click", (e) => {
    if (e.target.classList.contains("active")) {
      toggleMenu();
    }
    if (e.target.classList.contains("header-mobile_container")) {
      toggleMenu();
    }
  });
  function toggleMenu() {
    refs.menu.classList.toggle("active");

    if (refs.menu.classList.contains("active")) {
      document.body.style.overflow = "hidden";
      refs.openMenu.classList.add("active");
    } else {
      document.body.style.overflow = "";
      refs.openMenu.classList.remove("active");
    }
  }
})();

const container = document.getElementById("container");
const darkness = document.getElementById("darkness");

container.addEventListener("mousemove", (e) => {
  if (window.innerWidth > 1024) {
    const halfWidth = darkness.offsetWidth / 2;
    const halfHeight = darkness.offsetHeight / 2;

    darkness.style.top = `${e.pageY - halfHeight}px`;
    darkness.style.left = `${e.pageX - halfWidth}px`;
  } else {
    darkness.style.display = "none";
  }
});

window.addEventListener("resize", () => {
  if (window.innerWidth > 1024) {
    darkness.style.display = "block";
  } else {
    darkness.style.display = "none";
  }
});
